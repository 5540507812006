<template>
    <div :class="['card bg-base-100 shadow-xl p-4', { 'text-center': big }]">
      <div :class="['card-body', { 'flex flex-col items-center': big }]">
        <h2 :class="['card-title', { 'text-4xl': big }]">{{ title }}</h2>
        <p :class="['text-2xl', { 'text-5xl': big }]">{{ value }}</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      title: {
        type: String,
        required: true
      },
      value: {
        type: [String, Number],
        required: true
      },
      big: {
        type: Boolean,
        default: false
      }
    }
  }
  </script>