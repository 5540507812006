<template>
  <div class="min-h-screen bg-base-200">
    <header class="bg-primary text-primary-content p-4">
      <h1 class="text-center text-3xl">SirSouq.com</h1>
    </header>
    <main>
      <KpiDashboard />
    </main>
  </div>
</template>

<script>
import KpiDashboard from './components/KpiDashboard.vue';

export default {
  components: {
    KpiDashboard
  }
}
</script>