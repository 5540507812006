<template>
  <div>
      <div class="p-4">
        <KpiCard title="Valuation" :value="valuation" big />
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 p-4">
        <KpiCard title="Total Sales" :value="totalSales" />
        <KpiCard title="Revenue" :value="revenue" />
        <KpiCard title="New Customers" :value="newCustomers" />
        <KpiCard title="Customer Retention Rate" :value="customerRetentionRate + '%'" />
        <KpiCard title="Website Traffic" :value="websiteTraffic" />
        <KpiCard title="Conversion Rate" :value="conversionRate + '%'" />
        <KpiCard title="Order Fulfillment Time" :value="orderFulfillmentTime + ' days'" />
        <KpiCard title="Inventory Levels" :value="inventoryLevels" />
      </div>
    </div>
</template>
  
  <script>
  import KpiCard from './KpiCard.vue';
  
  export default {
    components: {
      KpiCard
    },
    data() {
      return {
        valuation: '$100.00',
        totalSales: 0,
        revenue: '$0.00',
        newCustomers: 0,
        customerRetentionRate: 0,
        websiteTraffic: 0,
        conversionRate: 0,
        orderFulfillmentTime: 0,
        inventoryLevels: 0
      };
    }
  }
  </script>